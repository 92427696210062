#babylonView {
  width: 100%;
  height: 600px;
}

#meshList {
  height: 700px;
  overflow: auto;
}

.meshCard {
  padding: 5px;
}

.ui.dimmer {
  display: flex !important;
}

.ui.modal {
  margin-top: 0;
}

.request-viewer container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.request-viewer fieldset {
  border: 1px solid gray;
  border-radius: 0.5em;
  margin-bottom: 10px;
}

.request-viewer legend {
  padding: 5px;
  font-size: medium;
}

.request-selector form {
  display: flex;
  flex-direction: column;
}

.request-selector .input-bar {
  display: flex;
  min-height: 30px;
  max-width: 300px;
}

.request-selector .fetch-bar {
  display: flex;
  min-height: 40px;
  max-width: 300px;
  font-size: large;
}

.input-bar input {
  flex: 1 1;
}

.request-selector input[type='text'] {
  border: 1px solid lightgray;
  text-align: center;
}

.request-selector input[type='button'] {
  width: 100px;
}

.request-selector input[type='submit'] {
  flex: 1 1;
}

.customer-badge {
  display: inline;
  border-radius: 0.5em;
  margin: 3px;
  padding: 2px 8px;
  font-weight: bold;
  font-size: small;
  border: 1px solid gray;
}

.customer-badge[data-oaci='AFR'] {
  color: white;
  border-color: #002157;
  background-color: #002157;
}

.customer-badge[data-oaci='KLM'] {
  color: white;
  background-color: #00a1e4;
  border-color: #00a1e4;
}

.customer-badge[data-oaci='IBE'] {
  color: #fcd100;
  background-color: #d7192d;
  border-color: #d7192d;
}

.customer-badge[data-oaci='AIS'] {
  color: white;
  background-color: #b74e95;
  border-color: #b74e95;
}

.customer-badge[data-oaci='SVA'] {
  color: white;
  background-color: #917b4c;
  border-color: #917b4c;
}

